import TimeIcon from "@/components/shared/icons/web/TimeIcon";
import { IGame } from "@/shared/model/game.model";
import dayjs from "dayjs"
import { useEffect, useState } from "react"


interface IData {
    setDisableDatCuoc: any;
    setLoad: any;
    load: boolean;
    ky: IGame | null
}
const KyQuay = (props: IData) => {

    const { setDisableDatCuoc, setLoad, load, ky } = props

    const [timeInterval, setTimeInterval] = useState('00:00')

    useEffect(() => {
        const gameEndTime = dayjs(ky?.endTime).add(10, 'seconds')

        const interval = setInterval(() => {
            const now = dayjs()
            const difference = gameEndTime.diff(now)

            if (difference > 0) {
                const hours = Math.floor(difference / (1000 * 60 * 60))
                const minutes = Math.floor(
                    (difference % (1000 * 60 * 60)) / (1000 * 60),
                )
                const seconds = Math.floor((difference % (1000 * 60)) / 1000)

                if (minutes === 0 && seconds < 15) {
                    setDisableDatCuoc(true)
                } else {
                    setDisableDatCuoc(false)
                }

                let m = minutes < 10 ? '0' + minutes : minutes
                let s = seconds < 10 ? '0' + seconds : seconds
                const formattedCountdown = `${m}:${s}`
                setTimeInterval(formattedCountdown)
            } else {
                setLoad(!load)
                setTimeInterval('Đang chờ kết quả')
                clearInterval(interval)
            }
        }, 1000)

        return () => clearInterval(interval)
    }, [ky?.endTime, load])

    return <div className="d-flex justify-content-center box-kyquay gap-3 align-items-center">
        <div className="box-kyid">
            Đơn hàng tiếp theo <span className="kyid fw-bold">{ky?.id}</span>
        </div>
        <div>|</div>
        <div className="time fw-bold d-flex align-items-center gap-2">
            <TimeIcon fill="#fff" width={20} height={20} style={{marginBottom: 2}}/>
            {timeInterval}</div>
    </div>
}

export default KyQuay