import Layout from "./Layout";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@/reducers";
import { CButton, CFormInput, CFormLabel } from "@coreui/react-pro";
import { useState } from "react";
import axios from '../../../shared/config/axios-interceptor';
import { ToastSuccess } from "@/components/shared/toast/Toast";
import ArrowLeft from "@/components/shared/icons/web/ArrowLeft";
import { useRouter } from "@/shared/utils/hooks/useRouter";
import { AppDispatch } from "@/store";
import { getProfile } from "../auth/auth.api";

const AddBank = () => {
    const { navigate } = useRouter();
    const dispatch = useDispatch<AppDispatch>()

    const { user } = useSelector((state: RootState) => state.authentication);

    const [cardName, setCardName] = useState(user?.cardName || '');
    const [cardNumber, setCardNumber] = useState(user?.cardNumber || '');
    const [bankName, setBankName] = useState(user?.bankName || '');

    const onChange = (index: number, e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        if (index === 1) {
            setCardName(value)
        }
        if (index === 2) {
            setCardNumber(value)
        }
        if (index === 3) {
            setBankName(value)
        }
    }

    const onSubmit = async () => {
        try {
            const response = await axios.put('/user/' + user?.id, {
                cardName,
                cardNumber,
                bankName
            });
            ToastSuccess('Cập nhật ngân hàng thành công')
            dispatch(getProfile())
        } catch (error) {
            console.error('Error:', error);
        }
    }

    return (
        <Layout >
            <div className="box-add-bank">

                <div className="box-header">
                    <ArrowLeft style={{ position: 'absolute', left: 16 }} onClick={() => navigate(-1)} />

                    <h5 className="m-0 text-white">Liên kết ngân hàng</h5>
                </div>

                <div className="box-form">
                    <div className="d-flex form-group-custom">
                        <CFormLabel htmlFor="cardName" className="col-sm-2 col-form-label text-nowrap">Chủ tài khoản</CFormLabel>
                        <CFormInput onChange={(e) => onChange(1, e)} type="text" id="cardName" placeholder="Nhập tên thật" value={cardName} />
                    </div>
                    <div className="d-flex form-group-custom">
                        <CFormLabel htmlFor="cardNumber" className="col-sm-2 col-form-label text-nowrap">Số tài khoản</CFormLabel>
                        <CFormInput onChange={(e) => onChange(2, e)} type="text" id="cardNumber" placeholder="Nhập số tài khoản" value={cardNumber} />
                    </div>
                    <div className="d-flex form-group-custom">
                        <CFormLabel htmlFor="bankName" className="col-sm-2 col-form-label text-nowrap">Ngân hàng</CFormLabel>
                        <CFormInput onChange={(e) => onChange(3, e)} type="text" id="bankName" placeholder="Nhập ngân hàng" value={bankName} />
                    </div>

                    <div className="px-3 mt-4">
                        <CButton className="w-100" onClick={onSubmit}>Cập nhật</CButton>
                    </div>
                </div>




            </div>
        </Layout>
    )
}

export default AddBank;