import SearchIcon from '@/components/shared/icons/header/SearchIcon'
import { RootState } from '@/reducers'
import { useRouter } from '@/shared/utils/hooks/useRouter'
import { getTruncateTxt } from '@/shared/utils/ultils'
import { AppDispatch } from '@/store'
import {
  CAvatar,
  CDropdown,
  CDropdownDivider,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CNavItem,
} from '@coreui/react-pro'
import dayjs from 'dayjs'
import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import avatar from '../../assets/img/avatar.jpg'
import { handleChatRoomName } from '../modules/Chat/Chat'
import { getEntities as getListChat } from '../modules/Chat/chat.api'
import {
  chatSelectors,
  fetching,
  initialChatFilter,
  setFilterState,
} from '../modules/Chat/chat.reducer'
import CreateChat from '../modules/Chat/CreateChat'
import ArrowNarrowLeftIcon from '../shared/icons/ArrowNarrowLeftIcon'
import EditIcon from '../shared/icons/EditIcon'
import MessageSquareIcon from '../shared/icons/sidebar/MessageSquareIcon'
import XIcon from '../shared/icons/XIcon'

const ChatNavItem = () => {
  const dispatch = useDispatch<AppDispatch>()
  const { navigate } = useRouter()
  const { user } = useSelector((state: RootState) => state.authentication)
  const { initialState } = useSelector((state: RootState) => state.chatReducer)
  const { filterState } = initialState
  const msgDropdownRef = useRef<HTMLDivElement | HTMLLIElement>(null)
  const chatRooms = useSelector(chatSelectors.selectAll)
  const [messageVisible, setMessageVisible] = useState<boolean>(false)
  const setMessageVisibleListener = (key: boolean) => () =>
    setMessageVisible(key)
  const [createChatVisible, setCreateChatVisible] = useState<boolean>(false)
  const [searchMsgKey, setSearchMsgKey] = useState<string>('')
  const [searchMode, setSearchMode] = useState<boolean>(false)

  useEffect(() => {
    if (messageVisible) {
      dispatch(fetching())
      dispatch(getListChat({ ...filterState, limit: 10 }))
    } else {
      setSearchMsgKey('')
      setSearchMode(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messageVisible, JSON.stringify(filterState)])

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (
        msgDropdownRef.current &&
        !msgDropdownRef.current.contains(event.target)
      ) {
        // Clicked outside the dropdown, close it
        setMessageVisible(false)
      }
    }

    // Attach the event listener on component mount
    document.addEventListener('mousedown', handleClickOutside)

    // Detach the event listener on component unmount
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (searchMsgKey.trim()) {
      const refetchTimer = setTimeout(() => {
        dispatch(setFilterState({ ...filterState, key: searchMsgKey.trim() }))
      }, 1000)
      return () => clearTimeout(refetchTimer)
    } else {
      dispatch(setFilterState(initialChatFilter))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchMsgKey])

  return (
    <>
      <CNavItem>
        <CDropdown alignment="end"
          visible={messageVisible}
          placement="bottom"
          autoClose={false}
          ref={msgDropdownRef}
          onHide={() => dispatch(setFilterState(initialChatFilter))}
        >
          <CDropdownToggle
            className="nav-icon"
            onClick={setMessageVisibleListener(!messageVisible)}
          >
            <MessageSquareIcon
              className={`header-icon ${messageVisible ? 'active' : ''}`}
              height={20}
              width={20}
            />
          </CDropdownToggle>
          <CDropdownMenu className="dropdown-menu-custom">
            <div className="dropdown-menu-header">
              <span className="d-flex align-items-center">Tin nhắn</span>
              <XIcon
                onClick={setMessageVisibleListener(false)}
                className="cursor-pointer"
              />
            </div>
            <div className={`dropdown-menu-action`}>
              {searchMode ? (
                <>
                  <ArrowNarrowLeftIcon
                    className="me-2 cursor-pointer"
                    onClick={(e) => {
                      setSearchMode(false)
                      setSearchMsgKey('')
                    }}
                  />
                  <CInputGroup className="input-start-group">
                    <CInputGroupText className="cursor-pointer">
                      <SearchIcon height={16} width={16} />
                    </CInputGroupText>
                    <CFormInput
                      placeholder="Tìm kiếm"
                      value={searchMsgKey}
                      name="key"
                      onChange={(e) => setSearchMsgKey(e.currentTarget.value)}
                    />
                  </CInputGroup>
                </>
              ) : (
                <>
                  <span
                    onClick={() => {
                      setCreateChatVisible(true)
                      setMessageVisible(false)
                    }}
                  >
                    <EditIcon height={16} width={16} />
                    Tạo mới
                  </span>
                  <span onClick={() => setSearchMode(true)}>
                    <SearchIcon height={16} width={16} />
                    Tìm kiếm
                  </span>
                </>
              )}
            </div>

            {chatRooms.map((room) => (
              <div
                key={`chat-room-${room.id}`}
                className="menu-item cursor-pointer"
                onClick={() => {
                  setMessageVisible(false)
                  navigate(`/admin/message/${room.id}`)
                }}
              >
                <div className="d-flex align-items-center me-md-12">
                  <CAvatar src={room.avatar || avatar} className="avatar-40" />
                </div>
                <div className="w-100 ms-12">
                  <div className="d-flex justify-content-between">
                    <p className="text-medium-sm text-gray-neutral-950 mb-1">
                      {handleChatRoomName(room, user)}
                    </p>
                    {room.latestMessage?.isRead === 0 ? (
                      <p className="mb-1 text-danger" style={{ fontSize: 12 }}>
                        Chưa đọc
                      </p>
                    ) : (
                      ''
                    )}
                  </div>
                  <div className="d-flex justify-content-between align-items-center text-xs text-gray-modern-400">
                    <p className="m-0">
                      {getTruncateTxt(room.latestMessage?.content, 30) ||
                        ' Đang cập nhật...'}
                    </p>
                    <p className="m-0">
                      {dayjs(room.latestMessage?.timestamp || room.createdDate)
                        .fromNow()
                        .replaceAll('một', '1')}
                    </p>
                  </div>
                </div>
              </div>
            ))}
            <CDropdownDivider />
            <CDropdownItem
              href="#/admin/message"
              onClick={setMessageVisibleListener(false)}
            >
              Xem tất cả
            </CDropdownItem>
          </CDropdownMenu>
        </CDropdown>
      </CNavItem>
      {createChatVisible ? (
        <CreateChat
          visible={createChatVisible}
          setVisible={setCreateChatVisible}
        />
      ) : null}
    </>
  )
}

export default ChatNavItem
