import BellIcon from '@/components/shared/icons/header/BellIcon';
import CalendarIcon from '@/components/shared/icons/header/CalendarIcon';
import LogoutIcon from '@/components/shared/icons/header/LogoutIcon';
import SearchIcon from '@/components/shared/icons/header/SearchIcon';
import SettingIcon from '@/components/shared/icons/header/SettingIcon';
import UserPlusIcon from '@/components/shared/icons/header/UserPlusIcon';
import UserSquareIcon from '@/components/shared/icons/header/UserSquareIcon';
import { RootState } from '@/reducers';
import { SystemRole } from '@/shared/enumeration/role';
import { useRouter } from '@/shared/utils/hooks/useRouter';
import { getTruncateTxt } from '@/shared/utils/ultils';
import { AppDispatch } from '@/store';
import { cilMenu } from '@coreui/icons-pro';
import CIcon from '@coreui/icons-react';
import {
  CAvatar,
  CContainer,
  CDropdown,
  CDropdownDivider,
  CDropdownHeader,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
  CFormInput,
  CHeader,
  CHeaderNav,
  CHeaderToggler,
  CInputGroup,
  CInputGroupText,
  CNav,
  CNavItem,
  CNavLink
} from '@coreui/react-pro';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { matchPath } from 'react-router-dom';
import avatar from '../../assets/img/avatar.jpg';
import { logout, resetAll } from '../modules/auth/auth.reducer';
import AppBreadcrumb from './AppBreadcrumb';
import ChatNavItem from './ChatNavItem';
import { handleGetHeaderItem, HeaderItem } from './NavItems';
import { toggleAside, toggleSidebar } from './reducer';

const TheHeader = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { redirectView, location } = useRouter();
  const { pathname } = location;
  const { sidebarShow, asideShow, customHeader } = useSelector((state: RootState) => state.container);
  const { user } = useSelector((state: RootState) => state.authentication);
  const isAdmin = user?.role === SystemRole.ADMIN;
  const [headerItem, setHeaderItem] = useState<HeaderItem | null>(null);

  const isChatView = pathname.includes('/message');

  const toggleSidebarDesktop = () => {
    dispatch(toggleSidebar(!sidebarShow));
  };

  const toggleASideDesktop = () => {
    dispatch(toggleAside(!asideShow));
  };

  const onLogout = () => {
    dispatch(logout());
    dispatch(resetAll());
  };

  useEffect(() => {
    const headerItem = handleGetHeaderItem(pathname);
    setHeaderItem(customHeader ? customHeader : headerItem);
  }, [pathname, customHeader]);

  const renderHeader = (item: HeaderItem | null) => {
    if (!item) return '';
    if (typeof item.name === 'string') {
      return <h1 className="header-title m-0 d-none d-sm-block">{item?.name}</h1>;
    }
    return item?.name;
  };

  return (
    <CHeader position="sticky" className="custom-header shadow-none">
      <CContainer fluid className="header-container">
        {/* <CHeaderBrand className="mx-auto d-md-none" >
      </CHeaderBrand> */}
        <CHeaderNav className="d-flex me-auto ">
          <CNavItem className="nav-breadcrumb d-none">
            <AppBreadcrumb />
          </CNavItem>
          {renderHeader(headerItem)}
          <CHeaderToggler
            className="ps-1 sidebar-toggler-header"
            style={{ padding: '8px' }}
            onClick={toggleSidebarDesktop}
          >
            <CIcon icon={cilMenu} size="lg" />
          </CHeaderToggler>
        </CHeaderNav>
        <CHeaderNav className="custom-form">
          {/* <CInputGroup className="input-start-group d-none d-lg-flex" style={{ marginRight: '8px' }}>
            <CInputGroupText id="search-addon" className="cursor-pointer">
              <SearchIcon />
            </CInputGroupText>
            <CFormInput placeholder="Tìm kiếm" name="keyword" onChange={(e) => console.log(e.currentTarget.value)} />
          </CInputGroup> */}
          {/* <CNavItem className="d-lg-none">
            <CNavLink className="nav-icon">
              <SearchIcon className="header-icon" />
            </CNavLink>
          </CNavItem> */}

          {!isChatView ? <ChatNavItem /> : null}

          {/* <CNavItem>
            <CNavLink className="nav-icon">
              <CalendarIcon className="header-icon" />
            </CNavLink>
          </CNavItem> */}
          {/* <CNavItem>
            <CNavLink className="nav-icon" onClick={toggleASideDesktop}>
              <BellIcon className="header-icon" />
            </CNavLink>
          </CNavItem> */}
        </CHeaderNav>
        <CHeaderNav className="header-dropdown-nav">
          <CDropdown variant="nav-item">
            <CDropdownToggle className="py-0" caret={false}>
              <CAvatar src={user?.avatar ?? avatar} className="avatar-32" />{' '}
              <span className="ms-2 d-none d-md-inline text-semibold-sm">
                {getTruncateTxt(user?.fullName || user?.username)}
              </span>
            </CDropdownToggle>
            <CDropdownMenu className="dropdown-menu">
              <CDropdownHeader className="dropdown-header">
                <CAvatar src={user?.avatar ?? avatar} className="avatar-40" />
                <div>
                  <p className="header-title m-0">{user?.fullName || user?.username}</p>
                  <p className="header-note m-0">{user?.email}</p>
                </div>
              </CDropdownHeader>
              <CDropdownDivider />

              <CDropdownItem href="#/admin/account/profile">
                <UserSquareIcon />
                Tài khoản
              </CDropdownItem>
              {/* {isAdmin ? (
                <CDropdownItem href="#/admin/settings/system-info">
                  <SettingIcon />
                  Cài đặt hệ thống
                </CDropdownItem>
              ) : null} */}
              {/* <CDropdownItem>
                <UserPlusIcon />
                Mời bạn bè
              </CDropdownItem> */}
              {/* <CDropdownItem onClick={redirectView('/admin/settings')}>
                <SettingIcon />
                Hỗ trợ & FAQs
              </CDropdownItem> */}
              <CDropdownDivider />
              <CDropdownItem href="#/admin/login" onClick={onLogout}>
                <LogoutIcon />
                Đăng xuất
              </CDropdownItem>
            </CDropdownMenu>
          </CDropdown>
        </CHeaderNav>
      </CContainer>
      {/* <CHeaderDivider /> */}
      {/* <CContainer fluid>
        <AppBreadcrumb />
      </CContainer> */}
      {headerItem && headerItem?.tabItems ? (
        <CContainer fluid className="page-tab-container">
          <CNav variant="underline" role="tablist" className="custom-nav">
            {headerItem.tabItems.map((item, index) => {
              const { name, path } = item;
              const isActive = matchPath({ path, end: false }, location.pathname);
              return (
                <CNavItem role="presentation" key={index}>
                  <CNavLink
                    active={Boolean(isActive)}
                    component="button"
                    type="button"
                    role="tab"
                    aria-controls="home-tab-pane"
                    aria-selected={Boolean(isActive)}
                    onClick={redirectView(path)}
                  >
                    {name}
                  </CNavLink>
                </CNavItem>
              );
            })}
          </CNav>
          {headerItem.rightComponent ? headerItem.rightComponent : null}
        </CContainer>
      ) : (
        ''
      )}
    </CHeader>
  );
};

export default TheHeader;
